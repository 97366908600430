





































































.color-picker-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;
  .vc-chrome-toggle-btn {
    display: none;
  }
  .vc-input__label {
    display: none !important;
  }
  .overlay {
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
  }
  .vc-chrome {
    position: absolute;
  }
  .vc-input__input {
    max-width: 140px;
  }
  .btn-clear {
    position: absolute;
    background: #f44250;
    border: none;
    color: #fff;
    font-weight: 500;
    font-size: 13px;
  }
}
